import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card border-light mt-5" }
const _hoisted_2 = {
  key: 0,
  class: "card-body card-custom-spacing-tblf-10 table-custom-invoice"
}
const _hoisted_3 = {
  key: 1,
  class: "card-body"
}
const _hoisted_4 = { class: "card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_notification_table = _resolveComponent("vc-notification-table")!
  const _component_vc_notification_last_layer = _resolveComponent("vc-notification-last-layer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_vc_notification_table)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, "Loading Information Please Wait...")),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_vc_notification_last_layer)
      ])
    ])
  ]))
}
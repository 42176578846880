
import { computed, defineComponent, onMounted, ref } from 'vue'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Etcs from '@/core/services/Callable/Etcs'
import router from '@/router/clean';
import { store } from '@/store'

export default defineComponent({
    setup() {
        
        const loading = ref(false)
        const listingPayload = computed(() => {
            return store.state.etc.notificationPayload
        })
        onMounted(async() => {
            setCurrentPageBreadcrumbs("Notifications", []);
            const page = router.currentRoute.value.query ? router.currentRoute.value.query.page : 1;
            await getLists(page)
        })

        const getLists = async (value: any) => {
            const page = parseInt(value ? value : 1);
            listingPayload.value.index = page;
            router.replace({ query: { page } })
            .catch(error => {
                if (
                    error.name !== 'NavigationDuplicated' &&
                    !error.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.log(error)
                    }
                });
            console.log(listingPayload.value);
            loading.value = true
            await Etcs.getAllNotifications(listingPayload.value)
            loading.value = false

        }

        return {
            listingPayload,
            loading
        }
    },
})
